<template>
  <b-row class="settings">
    <b-col>
      <PageHeader :title="$t('general.settingsPages.subscription.title')" />

      <b-row>
        <div class="settings__form">
          <div class="settings__input">
            <CInput
              type="text"
              name="price"
              theme="settings"
              class="settings__field"
              :disabled="!isCreatorRole"
              v-mask="currencyMask"
              :custom-errors="errors"
              :custom-field-name="
                $t('general.settingsPages.subscription.amount')
              "
              v-model="price"
              :label="$t('general.settingsPages.subscription.amount')"
            >
              <template #appendAfter>
                <transition name="fade" mode="out-in">
                  <CIcon
                    name="check"
                    class="settings__icon settings__icon--check"
                    v-if="isCreatorRole"
                  />
                  <CIcon
                    name="false"
                    class="settings__icon settings__icon--false"
                    v-else
                  />
                </transition>
              </template>

              <template #appendAddition>
                <span class="settings__counter">
                  <i18n
                    v-if="isCreatorRole"
                    tag="span"
                    class="settings__font settings__font--addition"
                    path="general.settingsPages.subscription.additionText"
                  />
                  <i18n
                    v-else
                    tag="span"
                    class="settings__font settings__font--addition"
                    path="general.settingsPages.subscription.additionTextNot"
                  />
                </span>
              </template>
            </CInput>
          </div>
        </div>

        <div class="settings__button-wrapper">
          <button
            type="button"
            @click.prevent="update"
            class="base-button settings__button"
          >
            <span class="base-button__font">
              {{ $t("general.settingsPages.subscription.button.update") }}
            </span>
          </button>
        </div>
      </b-row>
    </b-col>

    <div class="settings__group-title w-100">
      <span class="settings__font settings__font--group-title">
        {{ $t("general.settingsPages.subscription.groupTitle") }}
      </span>
    </div>

    <div class="settings__menu-button">
      <SettingsMenuButton :button="button" />
    </div>
  </b-row>
</template>
<script>
import PageHeader from "@/layout/PageHeader.vue";
import CInput from "@/features/ui/СInput.vue";
// import CIcon from "@/features/ui/CIcon.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import SettingsMenuButton from "@/features/ui/common/SettingsMenuButton.vue";
import CIcon from "@/features/ui/CIcon.vue";
import User from "@/components/models/User";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PageSettingsSubscription",
  components: {
    CIcon,
    SettingsMenuButton,
    // CIcon,
    CInput,
    PageHeader,
  },

  mixins: [waitRequest, validationError],
  computed: {
    ...mapGetters({ user: "user" }),
    isCreatorRole() {
      return this.user.role.includes(User.ROLE_CREATOR);
    },
    button() {
      return {
        id: 1,
        name: this.$t("general.settingsPages.subscription.button.bundles"),
        addition: "",
        action: () => this.$router.push("/settings-bundles"),
      };
    },
    currencyMask() {
      return createNumberMask({
        prefix: "$",
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
      });
    },
  },

  created() {
    if(!this.isCreatorRole) {
      this.$router.push('/404')
    }
    this.price = this.user?.face_price || "0"
  },



  data() {
    return {
      price: this.user?.face_price || "0"
    };
  },

  methods: {
    ...mapActions({
      createPrice: "price/createPrice",
    }),

    async update() {
      const isValid = await this.$validator.validateAll();

      if (!isValid || this.requestInProgress) return;

      return this.waitRequest(() => {
        const price = this.price?.replace("$", "")?.replaceAll(",", "") ?? "0";
        return this.createPrice({ price }).catch(this.checkErrors);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  &__form {
    margin-top: em(32);
    width: 100%;
    margin-right: em(16);
    margin-left: em(16);
  }

  &__button {
    margin-top: em(17);
    margin-right: em(15);
    margin-left: auto;
  }

  &__menu-button {
    width: 100%;
  }

  &__button-wrapper {
    display: flex;
    width: 100%;
    margin-top: em(19);
    border-top: 1px solid $app-gray-2;
  }

  &__input {
    margin-top: em(16);
    position: relative;
  }

  &__counter {
    position: absolute;
    top: 100%;
    right: em(0);
  }

  &__field {
  }

  &__group-title {
    padding: em(21) 0 em(21) em(20);
  }

  &__font {
    &--addition {
      font-family: $font-default;
      font-size: em(12);
      color: $app-font-color-placeholder;
    }

    &--group-title {
      font-family: $font-default;
      font-style: normal;
      line-height: normal;
      color: $black;
      font-size: em(18);
      font-weight: 500;
    }
  }

  &__icon {
    color: $app-gray-18;

    &--check {
      width: em(14);
      height: em(10);
    }

    &--false {
      width: em(15);
      height: em(15);
    }
  }
}

.base-button {
  $parent: &;
  width: auto;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 16) em(16, 16);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}
</style>
